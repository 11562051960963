

function ready(){
}

// ウインドウがリサイズされたら
function onWindowResizeEnd() {
}


document.addEventListener('turbolinks:load', () => {
  ready();
});